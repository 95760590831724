import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import originalDesign from "images/original-design.webp";
import bulmaDesign from "images/chuck-bulma.webp";
import gitlabPipeline from "images/gitlab-pipeline.webp";

const badges = [cardImages.react, cardImages.bootstrap];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Chucktester = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2020</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <h2>Design</h2>
            <p>
              My initial process for designing Chucktester involved sketching
              crude mockups on my whiteboard and then translating that design
              into code. The look was inspired by trading card binders like the
              one I used for my Pokémon cards growing up. I began the project
              using W3.css for the UI with some particularly bright color
              choices.
            </p>
            <Col
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className="mb-4 mx-auto"
              style={{ textAlign: "center" }}
            >
              <Image
                src={originalDesign}
                alt="First draft of Chucktester"
                fluid
              />
              <p>First Draft</p>
            </Col>
            <h2>Framework Selection</h2>
            <p>
              I quickly decided to use this opportunity to learn more about
              React and began converting my original design into a web
              application. After experimenting with CSS libraries like Bulma and
              Bootstrap, I settled on Bootstrap for styling the project.
            </p>
            <Col
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className="mb-4 mx-auto"
              style={{ textAlign: "center" }}
            >
              <Image
                src={bulmaDesign}
                alt="Chucktester rough draft using Bulma CSS library"
                fluid
              />
              <p>Experimenting with Bulma</p>
            </Col>
            <h2>Deployment</h2>
            <p>
              Once I began pushing changes to my live domain, I used GitLab
              pipelines to establish an automated test/build/deployment system.
              I use an old Macbook from 2008 running Ubuntu Server as my GitLab
              runner to avoid using shared runners.
            </p>
            <Col
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className="mb-4 mx-auto"
              style={{ textAlign: "center" }}
            >
              <Image
                src={gitlabPipeline}
                alt="Chucktester rough draft using Bulma CSS library"
                fluid
              />
              <p>Automation is Beautiful!</p>
            </Col>
            <h2>v1.0.0</h2>
            <p>
              I'm continuing to add functionality and content to the site as I
              push the project towards version 1.0. Since deployment I've also
              improved the Page Insights score for the home page from 69 to 90
              on mobile. I did this by configuring my server caching policy,
              making the site more accessible and optimizing my images.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Chucktester;
