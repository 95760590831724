import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import uhOhImage from "images/chuckError.svg";

const NotFound = () => {
  return (
    <Row className="my-4 justify-content-center">
      <Card>
        <Row>
          <Col>
            <Card.Img
              src={uhOhImage}
              className="px-4 py-4"
              variant="top"
            ></Card.Img>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default NotFound;
