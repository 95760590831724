import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import iceCreamed from "images/ice-creamed.jpg";
import bofficePhoto from "images/boffice.jpg";

import { ReactComponent as AppStoreBadge } from "images/appStoreBadge.svg";
import { ReactComponent as GooglePlayBadge } from "images/googlePlayBadge.svg";

const badges = [cardImages.unity, cardImages.cSharp];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Creamed = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>Released May 2020</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <h1>Gameplay Summary</h1>
            <p>
              Creamed involves steering an ice cream truck to avoid oncoming
              traffic while simultaneously firing ice cream at hungry customers
              crowding the sidewalk. Players increase their score by matching
              correct flavors with the customers who prefer them.
            </p>
            <h3>I contributed code to:</h3>
            <ul>
              <li>Player movement</li>
              <li>Upgrades and progression</li>
              <li>Score multipliers</li>
              <li>UI</li>
              <li>Leaderboards</li>
              <li>Achievements</li>
            </ul>
            <h1>Project Development</h1>
            <p></p>
            <h2>Beta Testing</h2>
            <p>
              I provided builds to both Google and Apple during our Beta and
              Alpha testing phases and distributed updates to a small group of
              beta testers. This process provided us with valuable feedback that
              we used to improve the game. Seeing the final stages of the game
              come together during this time was very exciting.{" "}
            </p>
            <h2>Release and Beyond!</h2>
            <p>
              Following our launch on May 30th, 2020 we quickly began working on
              a post-launch update that included raising the framerate limit and
              adding our mascot as a rare spawn.
            </p>
            <p>
              The game exceeded 200 downloads and has recieved many 5 star
              reviews on both platforms. I'm very proud of what we accomplished
              with the project. The lessons learned during development were
              invaluable and opened up a world of possibilities.
            </p>
            <Button variant="secondary" className="mb-4 non-card-link mx-auto">
              <a
                href="https://www.tinyent.com/creamed"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                Creamed Page on tinyEnt Site
              </a>
            </Button>
            <Row className="appStoreButton">
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mx-auto mb-4">
                <Button
                  variant="link"
                  size="lg"
                  href="https://apps.apple.com/us/app/id1506518174"
                  aria-label="Apple app store icon"
                >
                  <AppStoreBadge />
                </Button>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mx-auto mb-4">
                <Button
                  variant="link"
                  size="lg"
                  href="https://play.google.com/store/apps/details?id=com.tinyentstudios.Creamed"
                  aria-label="Google Play store icon"
                >
                  <GooglePlayBadge className="badgeStyle" />
                </Button>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="mx-auto mb-4">
                <Image
                  src={bofficePhoto}
                  alt="TinyEnt Studios bug bash for Creamed"
                  fluid
                />
                <p>Our first tinyEnt Studios bug bash</p>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="mx-auto mb-4">
                <Image
                  src={iceCreamed}
                  alt="Enjoying ice cream after a Creamed milestone"
                  fluid
                />
                <p>Enjoying ice cream after a Creamed milestone</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Creamed;
