import cardImages from "data/imageAssets";

const cardAssets = {
  images: cardImages,
};

const workDeck = [
  {
    cardData: {
      tradingCardTitle: "Easy Games",
      tradingCardSubtitle: "Game Dev",
      tradingCardSkills: ["Roblox", "Multiplayer"],
      images: cardAssets.images.nyoka,
      badges: [cardAssets.images.typescriptIcon],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Oombly",
      tradingCardSubtitle: "Game Dev",
      tradingCardSkills: ["Mobile Development", "Product Development", "CI/CD Pipelines"],
      images: cardAssets.images.fitnessHero,
      badges: [cardAssets.images.unity],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Denali",
      tradingCardSubtitle: "Telecom",
      tradingCardSkills: ["Network Testing", "Performance Testing"],
      images: cardAssets.images.networkHero,
      badges: [cardAssets.images.pythonIcon],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Spectralux",
      tradingCardSubtitle: "Avionics",
      tradingCardSkills: ["Test Procedures", "Documentation"],
      images: cardAssets.images.pilotHero,
      badges: [cardAssets.images.pythonIcon],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Monscierge",
      tradingCardSubtitle: "Hospitality",
      tradingCardSkills: ["Mobile Development", "Support"],
      images: cardAssets.images.iphoneHero,
      badges: [cardAssets.images.swiftIcon],
    },
  },
  {
    cardData: {
      tradingCardTitle: "tinyEnt",
      tradingCardSubtitle: "Founder",
      tradingCardSkills: ["Mobile Development", "Project Management"],
      images: cardAssets.images.startupHero,
      badges: [cardAssets.images.unity],
    },
  },
  {
    cardData: {
      tradingCardTitle: "G33ks",
      tradingCardSubtitle: "Business Owner",
      tradingCardSkills: ["Electronics Repair", "Customer Service"],
      images: cardAssets.images.repair,
      badges: [cardAssets.images.repairImage],
    },
  },
  {
    cardData: {
      tradingCardTitle: "GPAGNW",
      tradingCardSubtitle: "Nonprofit",
      tradingCardSkills: ["Web Development", "Dogs!"],
      images: cardAssets.images.webDevHero,
      badges: [cardAssets.images.html5],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Creamed",
      tradingCardSubtitle: "Game Dev",
      tradingCardSkills: ["Game Design", "UI", "Ice Cream!"],
      images: cardAssets.images.iphoneHero,
      badges: [cardAssets.images.unity],
    },
  },
  // {
  //   cardData: {
  //     tradingCardTitle: "Thruster Buster",
  //     tradingCardSubtitle: "Game Dev",
  //     tradingCardSkills: ["Game Design", "UI", "Project Management"],
  //     images: cardAssets.images.iphoneHero,
  //     badges: [cardAssets.images.unity],
  //   },
  // },
];

const playDeck = [
  {
    cardData: {
      tradingCardTitle: "Coding Dojo",
      tradingCardSubtitle: "Education",
      tradingCardSkills: ["Coding Bootcamp", "Full Stack"],
      images: cardAssets.images.schoolHero,
      badges: [cardAssets.images.codingDojo],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Duolingo",
      tradingCardSubtitle: "Deutsche",
      tradingCardSkills: ["Sprachen Lernen"],
      images: cardAssets.images.berlinHero,
      badges: [cardAssets.images.duolingo],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Spartan",
      tradingCardSubtitle: "Mount Olympus",
      tradingCardSkills: ["5k, 10k, and 21k", "Seismic Toss"],
      images: cardAssets.images.fitnessHero,
      badges: [cardAssets.images.spartanHelmet],
    },
  },
  {
    cardData: {
      tradingCardTitle: "Chucktester",
      tradingCardSubtitle: "Portfolio Site",
      tradingCardSkills: ["React", "Bootstrap"],
      images: cardAssets.images.schoolHero,
      badges: [cardAssets.images.react],
    },
  },
];

const allCards = {
  workDeck: workDeck,
  playDeck: playDeck,
};
export default allCards;
