import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import TradingCard from "components/cards/TradingCard";
import allCards from "data/cardAssets";
import chuckBust from "images/chuckBust.svg";
import chuckFooter from "images/chuckFooter.svg";
import Emoji from "components/common/Emoji";

const Home = () => {
  const allMyCards = allCards;
  const workDeck = allMyCards.workDeck;
  const playDeck = allMyCards.playDeck;

  const workCards = workDeck.map((tradingCard) => (
    <TradingCard
      key={tradingCard.cardData.tradingCardTitle}
      tradingCard={tradingCard}
    />
  ));
  const playCards = playDeck.map((tradingCard) => (
    <TradingCard
      key={tradingCard.cardData.tradingCardTitle}
      tradingCard={tradingCard}
    />
  ));
  return (
    <div className="home">
      <Row>
        <Col xs={8} sm={8} md={6} lg={4} xl={4} className="mx-auto mb-4">
          <Image
            src={chuckBust}
            alt="A Greek bust that's supposed to look like me."
          />
        </Col>
      </Row>
      <Row>
        <Col className="mx-auto" xs={10} sm={10} md={10} lg={10} xl={10}>
          <p>
            I'm a software developer living in Woodinville, WA. Over the last
            several years I've been working as a game developer and have
            worked on 4 released games. I've primarily worked with C# and Typescript and lately I've had a blast learning about Godot.
          </p>
          <p>
            When I'm not working on software, some hobbies that I enjoy are:
          </p>
          <p className="emoji-enlarged">
            <Emoji symbol="📚" label="books" />
            <Emoji symbol="🕹️" label="joystick" />
            <Emoji symbol="🏂" label="snowboarding" />
            <Emoji symbol="🏊‍♂️" label="swimming" />
            <Emoji symbol="🗺️" label="world map" />
            <Emoji symbol="🏃‍♂️" label="running" />
          </p>
          <p>
            Reach out if I can help you build something cool, or if you just
            want to connect. Thanks for stopping by!
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="my-2">
          <h2>TECH DECK</h2>
        </Col>
      </Row>
      <Row>{workCards}</Row>
      <Row>
        <Col className="my-2">
          <h2>PLAY DECK</h2>
        </Col>
      </Row>
      <Row>{playCards}</Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="my-4">
          <Image
            src={chuckFooter}
            alt="Me, standing at my desk working on updates for this website."
          />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
