import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";

const badges = [cardImages.unity, cardImages.cSharp];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const ThrusterBuster = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>Page Under Construction!</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <h1>Gameplay Summary</h1>
            <p>Coming soon!</p>
            <h1>Project Development</h1>

            <Button variant="secondary" className="mb-4 non-card-link mx-auto">
              <a
                href="https://www.tinyent.com/thruster-buster"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                Thruster Buster Page on tinyEnt Site
              </a>
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ThrusterBuster;
