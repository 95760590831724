import React from "react";
import "styles/ribbon.scss";
import PropTypes from "prop-types";

const Ribbon = ({ribbonText, ribbonLength}) => {
  
  const ribbonStyle = {
    display: "inline-block"
  }
  if (ribbonLength === "block") {
    ribbonStyle.display = "block"
  }
  return (
    <div className="ribbon-wrapper" style={ribbonStyle}>
      <div className="ribbon mx-2">
        <div className="ribbon-inner">{ribbonText}</div>
      </div>
    </div>
  );
};

Ribbon.propTypes = {
  ribbonText: PropTypes.string,
  ribbonLength: PropTypes.string,
};

Ribbon.defaultProps = {
  tradingCard: {
    ribbonText: "Missing Text!",
    ribbonLength: "block",
  },
};

export default Ribbon;
