import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import envoyPicture from "images/envoy.webp";

const badges = [cardImages.pythonIcon, cardImages.cSharp];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Spectralux = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2017 - 2019</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              I was one of two initial hires for a testing team at Spectralux
              Avionics. The team expanded with time, mostly with graduates from
              Coding Dojo. The majority of our time was spent writing and updating test
              scripts for Data Link Communication hardware. We wrote Python
              scripts to test functionality of the hardware and provided testing
              artifacts for certifications. Scripts were requirement-driven using
              IBM's Rational DOORS.
            </p>
            <h2>What's Data Link Hardware?</h2>
            <p>
              At a high level, Data Link Communication can be described as
              "texting for pilots". The Envoy and hardware like it can be an
              advantage to pilots and ground control by preventing communication
              issues that arise from communicating via voice.
            </p>
            <p>Other projects that I was involved with:</p>
            <ul>
              <li>Data validation for a configuration tool using C#</li>
              <li>Authored test procedure for I2C device</li>
              <li>Created a Windows installer for demo software</li>
            </ul>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <Image
                  src={envoyPicture}
                  fluid
                  alt="Envoy Data Link Hardware"
                />
                <p>Envoy Data Link Hardware</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Spectralux;
