import React from "react";
import { Image } from "react-bootstrap";

const Badge = ({ badge, badgeStyle }) => {
  const icon = badge;
  const myBadgeStyle = badgeStyle;
  return (
    <Image
      className={myBadgeStyle.myClassName}
      style={myBadgeStyle.myStyles}
      src={icon}
    />
  );
};

export default Badge;
