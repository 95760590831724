import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import headerContent from "data/headerContent";
import Ribbon from "components/common/Ribbon";

const Header = ({ currentPath }) => {
  const newCurrentPath = currentPath;
  var updatedHeaderContent = "Welcome!";
  if (newCurrentPath in headerContent) {
    updatedHeaderContent = headerContent[newCurrentPath];
  } else {
    updatedHeaderContent = headerContent["/404"];
  }
  const headerStyle = {
    display: "inline-block"
  }

  return (
    <Row className="header my-4" style={headerStyle}>
      <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
        <Link to="/" aria-label="home" >
          <FontAwesomeIcon icon={faHome} alt="Home" size="2x"></FontAwesomeIcon>
        </Link>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className="my-2">
        <Card className="shadow mx-auto px-5">
          <div className="my-2 mx-2">
            <Card.Title>
              <h1>
                <strong>{updatedHeaderContent.title}</strong>
              </h1>
            </Card.Title>
            <Card.Subtitle>
              <h2>{updatedHeaderContent.subtitle}</h2>
            </Card.Subtitle>

            <Card.Body>
              <Ribbon
                ribbonText={updatedHeaderContent.body}
                ribbonLength="inline-block"
              ></Ribbon>
            </Card.Body>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Header;
