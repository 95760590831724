import React from "react";
import Badge from "components/cards/Badge";

const BadgeRow = ({ tradingCardBadges, badgeStyle }) => {
  const badgeIcons = tradingCardBadges;
  // Loop through array of badges and map each element to a Badge component
  const badges = badgeIcons.map((badge) => <Badge key={badge} badge={badge} badgeStyle={badgeStyle} />);
  return <div>{badges}</div>;
};

export default BadgeRow;
