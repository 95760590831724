import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import codingDojoPhoto from "images/codingDojo.jpg";

const badges = [
  cardImages.pythonIcon,
  cardImages.swiftIcon,
  cardImages.js,
  cardImages.bootstrap,
];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const CodingDojo = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2016</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              Coding Dojo is a 4-month Web Development bootcamp. I completed the
              onsite training at the Bellevue campus with demo projects in
              Python, iOS and MEAN stacks. Each tech stack concluded with a
              project week where cohorts split into groups and worked together
              on software projects.
            </p>
            <h2>Python - Cicero The Jerk</h2>
            <p>
              My Python demo project was a Django Web App built to display
              automated Tweets when my cat (Cicero) was shaking his robot chef.
              A Raspberry Pi attached to his automated food bowl would detect
              this infraction and send a Tweet including a cat gif. Tweets
              appeared as a list in real time. I did not continue the project
              after presenting, but the Twitter account still exists!
            </p>
            <a
              href="https://twitter.com/CiceroBarkley"
              target="_blank"
              rel="noopener noreferrer"
            >
              @CiceroBarkley
            </a>
            <h2>iOS - PizzaMe!</h2>
            <p>
              I collaborated with two classmates to create a location based
              pizza locator app for iOS. The user would be presented with a list
              of nearby pizza restaurants and could select one to place a call.
            </p>
            <h2>Zipventer</h2>
            <p>
              Zipventer allowed users to post messages grouped by zip code.
              Messages could be retrieved by searching for the provided zip.
            </p>
            <Row style={{ textAlign: "center" }} className="mt-4">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Image src={codingDojoPhoto} alt="Screens and code" fluid />
                <p>Screens and code</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CodingDojo;
