import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import spartanBucket from "images/spartan-bucket.jpg";

const badges = [cardImages.spartanHelmet];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Spartan = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>8 Completed Races</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              Spartan races are obstacle courses of varying lengths with between
              20 and 30 obstacles.
            </p>
            <p>
              I ran my first Spartan race with coworkers from Spectralux in
              2017. I really enjoyed working together to overcome the physical
              challenges of a 5k run with obstacles mixed in.{" "}
            </p>
            <p>
              Since then, I've completed the Sprint (5k), Super (10k) and Beast
              (21k) races. I've run with friends and family in Washington,
              Hawaii, Texas and Canada. In 2019 I completed my first Trifecta
              which requires running each race in a calendar year.
            </p>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <Image
                  src={spartanBucket}
                  alt="I carry a bucket during a Spartan race"
                  fluid
                />
                <p>Me carrying a bucket during a Spartan race</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Spartan;
