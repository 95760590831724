import React from "react";

import Skill from "components/cards/Skill";

// TODO: Accept props for RARE or shiny skills
const SkillsList = ({ skills }) => {
  const listedSkill = skills;
  const skillsToList = listedSkill.map((skill) => (
    <Skill key={skill} skill={skill} />
  ));

  return <div>{skillsToList}</div>;
};

export default SkillsList;
