import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import starHollow from "images/cards/star-hollow-teal.png";

const Skill = ({ skill }) => {
  const listedSkill = skill;
  return (
    <Row className="mt-2">
      <Col xs={2} sm={2} md={2} lg={2}>
        <Image style={{ width: 16 }} src={starHollow} />
      </Col>
      <Col xs={10} sm={10} md={10} lg={10} style={{ textAlign: "left" }}>
        <Card.Text>{listedSkill}</Card.Text>
      </Col>
    </Row>
  );
};

export default Skill;
