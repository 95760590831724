import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import muensterPhoto from "images/munster.jpg";
import duo2020 from "images/duo-2020.webp";
import axios from "axios";
import Emoji from "components/common/Emoji";

const badges = [cardImages.duolingo, cardImages.germany];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Duolingo = () => {
  const [myStreak, setStreak] = useState("N/A");
  const [streakDate, setStreakDate] = useState("N/A");
  // Full URL: https://www.duolingo.com/2017-06-30/users/10280302?fields=streak

  // useEffect(() => {
  //   // myStreak should only be N/A on first load
  //   if (myStreak === "N/A") {
  //     const today = new Date();
  //     // This is picked up by NGINX proxy to avoid CORS errors
  //     axios("/streak")
  //       .then((response) => {
  //         setStreak(response.data.streak + " days");
  //         setStreakDate(today.toDateString());
  //       })
  //       .catch((error) => {
  //         setStreak("Could not get streak data");
  //       });
  //   } else {
  //     console.log("🦸‍♂️ Preventing repeating API call 🦸‍♂️");
  //   }
  // }, [myStreak]);

  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2016 - Present</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              I began using the Duolingo mobile app to learn German in 2016. I
              include it here because I'm proud of my daily streak. I've
              completed a German lesson every single day for:
            </p>{" "}
            <div style={{ textAlign: "center" }}>
              <h3>
                <Emoji symbol="🎉" label="party popper" />
                3050 days
                <Emoji symbol="🎉" label="party popper" />
              </h3>
              <p>(as of 5/10/2024) </p>
            </div>
            <Row style={{ textAlign: "center" }}>
              <Col xs={10} sm={10} md={6} lg={6} className="mb-4 mx-auto">
                <Image
                  src={duo2020}
                  alt="My 2020 Duolingo year in review"
                  fluid
                />
                <p>2020 Year in Review</p>
              </Col>
            </Row>
            <p>
              While I'm far from fluent with the language, I'm able to follow
              some basic conversations. This came in handy when I travelled to
              Germany on a vacation. I hope to continue my streak for as long as
              the Duolingo servers remain online.
            </p>
            <Row style={{ textAlign: "center" }}>
              <Col xs={8} sm={8} md={8} lg={8} className="mb-4 mx-auto">
                <Image
                  src={muensterPhoto}
                  alt="Photo of St. Lamberti Church in Münster, Germany"
                  fluid
                />
                <p>St. Lamberti Church in Münster, Germany</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Duolingo;
