import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";

const badges = [cardImages.swiftIcon, cardImages.githubOctoIcon];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const Monscierge = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2013 - 2016</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              Monscierge is a technology firm that provides a SaaS platform for
              hotels and hospitals.
            </p>
            <h2>Support</h2>
            <p>
              The bulk of my time at Monscierge was spent on the Support team.
              My duties included troubleshooting customer issues, configuring
              hardware for customer use and testing the company’s mobile
              applications. I would also visit local hotels to troubleshoot
              hardware issues and gather feedback from users.
            </p>
            <h2>Jr. iOS Developer</h2>
            <p>
              I pushed fixes for several bugs within the iOS version as I gained familiarity with the code-base. I also began development for the Mercy Hospital application.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Monscierge;
