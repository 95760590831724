import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import raspberyPis from "images/raspberry-pis.webp";

const badges = [
  cardImages.pythonIcon,
  cardImages.elasticIcon,
  cardImages.linuxTux,
  cardImages.githubOctoIcon,
];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};
const Denali = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2019 - 2020</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              I joined a small QA team working as a consultant for T-Mobile in
              2019. The main objective for our lab was to develop and implement
              test plans for network hardware. My focus as the team's first SDET
              was to familiarize myself with existing test procedures and
              expand/automate testing.{" "}
            </p>
            <p>
              In collaboration with the QA testers I began a Django Web App
              designed to centralize testing results and automate targeted test
              cases. Frequent use of tools like iPerf, the Python speedtest-cli
              occasionally, and Wireshark.
            </p>
            <p>
              Later, myself and an additional SDET led the QA process for a Ruby
              on Rails business Web App. Open source tools like Locust.io used
              for load testing hundreds of concurrent users and reporting on
              server performance.
            </p>
            <h2>Favorite Project</h2>
            <p>
              Creating a Raspberry Pi image used to automate network testing and
              monitor networking hardware. Each cloned image included scheduled
              tests and was distributed across my team. I've worked with the
              Raspberry Pi on personal projects for years so I was thrilled to
              include them in our testing strategy.
            </p>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <Image
                  src={raspberyPis}
                  fluid
                  alt="A 3 x 3 grid of Raspberry Pi's fresh out of the oven"
                />
                <p>A 3 x 3 grid of Raspberry Pi's fresh out of the oven</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Denali;
