import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithubAlt,
  faGitlab,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="footer my-2 py-2" style={{ textAlign: "center" }}>
      <a
        href="https://www.linkedin.com/in/matthew-mitchell-29639b79/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="mx-2" />
      </a>
      <a
        href="https://github.com/Jealousmango"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithubAlt} size="2x" className="mx-2" />
      </a>
      <a
        href="https://gitlab.com/Jealousmango"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGitlab} size="2x" className="mx-2" />
      </a>
      <a
        href="mailto:hi@chucktester.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faEnvelope} size="2x" className="mx-2" />
      </a>
      <p>
        <small>{`v${process.env.REACT_APP_VERSION}`}</small>
      </p>
    </div>
  );
};

export default Footer;
