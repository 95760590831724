import pythonIcon from "images/cards/python.png";
import swiftIcon from "images/cards/swift.png";
import elasticIcon from "images/cards/elastic.png";
import denaliImage from "images/cards/denali.png";
import spectraluxImage from "images/cards/spectralux.png";
import monsciergeImage from "images/cards/monscierge.png";
import repairImage from "images/cards/repair-guy.png";
import unity from "images/cards/unity.png";
import react from "images/cards/react.png";
import duolingo from "images/cards/duolingo.png";
import codingDojo from "images/cards/coding-dojo.png";
import spartan from "images/cards/spartan.png";
import spartanHelmet from "images/cards/spartan-helmet.png";
import html5 from "images/cards/html5.png";
import starHollow from "images/cards/star-hollow-teal.png";
import cSharp from "images/cards/c-sharp.png";
import js from "images/cards/js.png";
import css from "images/cards/css.png";
import germany from "images/cards/germany.png";
import bootstrap from "images/cards/bootstrap.png";
import linuxTux from "images/cards/linux-tux.png";
import githubIcon from "images/cards/github-icon.png";
import typescriptIcon from "images/cards/typescript-icon.svg";
import githubOctoIcon from "images/cards/github-octocat.png";
// card-hero images imported
import webDevHero from "images/card-hero/web_developer.webp";
import iphoneHero from "images/card-hero/iphone.webp";
import networkHero from "images/card-hero/network.webp";
import pilotHero from "images/card-hero/pilot.webp";
import startupHero from "images/card-hero/startup.webp";
import schoolHero from "images/card-hero/school.webp";
import fitnessHero from "images/card-hero/fitness.webp";
import berlinHero from "images/card-hero/berlin.webp";
import repair from "images/card-hero/repair.webp";
import easyGhostIcon from "images/cards/easy_ghost_narrow.webp";
import robloxStudioIcon from "images/cards/roblox_studio.png";
import nyoka from "images/easy/nyoka.webp";

import tbdImage from "images/cards/tbd.png";

const imageAssets = {
  pythonIcon: pythonIcon,
  swiftIcon: swiftIcon,
  elasticIcon: elasticIcon,
  denaliImage: denaliImage,
  spectraluxImage: spectraluxImage,
  monsciergeImage: monsciergeImage,
  repairImage: repairImage,
  unity: unity,
  react: react,
  duolingo: duolingo,
  codingDojo: codingDojo,
  spartan: spartan,
  spartanHelmet: spartanHelmet,
  starHollow: starHollow,
  webDevHero: webDevHero,
  iphoneHero: iphoneHero,
  networkHero: networkHero,
  pilotHero: pilotHero,
  startupHero: startupHero,
  schoolHero: schoolHero,
  fitnessHero: fitnessHero,
  html5: html5,
  cSharp: cSharp,
  js: js,
  css: css,
  germany: germany,
  bootstrap: bootstrap,
  tbdImage: tbdImage,
  berlinHero: berlinHero,
  repair: repair,
  linuxTux: linuxTux,
  githubIcon: githubIcon,
  githubOctoIcon: githubOctoIcon,
  typescriptIcon: typescriptIcon,
  robloxStudioIcon: robloxStudioIcon,
  easyGhostIcon: easyGhostIcon,
  nyoka: nyoka,
};

export default imageAssets;
