import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import tinyEntLogo from "images/tinyEntLogo.png";

const badges = [
  cardImages.react,
  cardImages.bootstrap,
  cardImages.unity,
  cardImages.cSharp,
];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const TinyEnt = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2017 - Present</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              I started self-learning Unity partly to become familiar with C#.
              I've played video games for most of my life so the chance to
              potentially learn a new skill while peeking behind the game
              development curtain was appealing. Over a weekend in 2017 my
              friends and I hacked away on an endless runner mobile game that we
              called Creamed.
            </p>
            <h2>tinyEnt Studios LLC is formed!</h2>
            <p>
              We all enjoyed working on the project together and quickly
              determined that a path for releasing the game in some capacity
              could be worth exploring. After spending way too much time
              choosing our name and formalizing our company's "Trial by fire"
              policy for removing members via deathmatches, we formed an LLC and
              began work to refactor most of the Creamed project.
            </p>
            <p>
              After Creamed released, we began updating our website. Eager to
              return to web development, I started a React project to replace
              our initial site. It's a work-in-progress, but I'm proud of the
              architecture of the project.{" "}
            </p>
            <p>
              We've been building prototypes and exprimenting with gameplay ideas since the launch of Creamed and I can't wait to talk more about that in the future!
            </p>
            <Button variant="secondary" className="mb-4 non-card-link mx-auto">
              <a
                href="https://www.tinyent.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                tinyEnt Website
              </a>
            </Button>

            <Row style={{ textAlign: "center" }}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mx-auto mb-4">
                <Image
                  src={tinyEntLogo}
                  alt="TinyEnt Studios bug bash for Creamed"
                  fluid
                />
                <p>tinyEnt Brent</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TinyEnt;
