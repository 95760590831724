import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import oldGpagnwPhoto from "images/before-gpagnw.webp";
import newGpagnwPhoto from "images/after-gpagnw.webp";

const badges = [cardImages.html5, cardImages.js, cardImages.css];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const GPAGNW = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>Updated site launched July 2020</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              I adopted my Greyhound Yzma from the GPAGNW several years ago.
              Yzma is the goofiest dog I know and I was thrilled with this
              organization for helping me find her.
            </p>
            <p>
              Their original website was built years ago. Some updates were
              needed to make sure that mobile users had a good experience and a
              modern design was desired. I worked closely with one of the
              members of GPAGNW as well as a designer to address these issues.
            </p>
            <p>
              The updated site was built using HTML/CSS and some Javascript. I
              used the W3.css library for the responsive layout and general
              styling.
            </p>
            <p>
              I continue to make site updates as needed and hope to expand the
              site functionality in the future!
            </p>
            <Button variant="secondary" className="mb-4 non-card-link mx-auto">
              <a
                href="https://www.gnwgreyhounds.org/index.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                GPA GNW Website
              </a>
            </Button>

            <Row style={{ textAlign: "center" }}>
              <Col xs={10} sm={10} md={6} lg={6} className="mx-auto">
                <Image
                  src={oldGpagnwPhoto}
                  alt="Screenshot of the original website"
                  fluid
                />
                <p>Original GPAGNW Website</p>
              </Col>
              <Col xs={10} sm={10} md={6} lg={6} className="mx-auto">
                <Image
                  src={newGpagnwPhoto}
                  alt="Screenshot of the updated website"
                  fluid
                />
                <p>Updated GPAGNW Website</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GPAGNW;
