import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import starburstCrunches from "images/me-starburst-crunches.png";
import suitUp from "images/suit-up.png";
import oomblyArcade from "images/oombly-arcade-chest.png";
import { ReactComponent as AppStoreBadge } from "images/appStoreBadge.svg";

const badges = [cardImages.unity, cardImages.cSharp];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};
const OomblyInteractive = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2021- 2022</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              Oombly Interactive (formerly Flip! Fitness) developed casual
              exercise games to encourage healthy movement. Their first release
              Hit The Deck! was available as a single player game when I was
              hired as a Software Developer on the project.
            </p>
            <h3>Hit The Deck!</h3>
            <p>
              My initial work on the project included establishing processes for
              development of the upcoming multi-player mode as well as
              refactoring the UI systems to support the new features. I cleaned
              up the existing codebase to reduce the project size by over a
              gigabyte and worked closely with our back-end engineer to support
              real-time gameplay using AWS services like Lambda and DynamoDB.
            </p>
            <p>
              I even had a chance to make an appearance in-game performing one
              of the exercises!
            </p>
            <Row style={{ textAlign: "center" }}>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                className="mb-4 mx-auto"
              >
                <Image
                  src={starburstCrunches}
                  alt="Me performing the Starburst Crunches exercise"
                  fluid
                />
                <p>Look, ma! I'm in a video game!</p>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={6}
                xl={6}
                xxl={6}
                className="mb-4 mx-auto"
              >
                <Image src={suitUp} alt="Suit Up!" fluid />
                <p>Screenshot of Suit Up!</p>
              </Col>
            </Row>
            <h3>Oombly Arcade</h3>
            <p>
              We took the lessons learned from Hit the Deck and began work on a
              second title in early 2022. I setup our new project with CI/CD
              pipelines to automate our builds and deployments to allow the team
              to quickly reach players for frequent testing sessions. We
              reviewed feedback weekly and found many ways to improve the game
              based on what our testers experienced.
            </p>
            <Row style={{ textAlign: "center" }}>
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="mb-4 mx-auto"
              >
                <Image src={oomblyArcade} alt="Suit Up!" fluid />
                <p>Oombly Arcade</p>
              </Col>
            </Row>
            <p>
              A few systems that I'm particularly proud of working on:
            </p>
            <ul>
              <li>Analytics</li>
              <li>Notifications</li>
              <li>User Interface</li>
              <li>Deck Customization</li>
            </ul>
            <Row className="appStoreButton">
              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mx-auto mb-4">
                <Button
                  variant="link"
                  size="lg"
                  href="https://apps.apple.com/us/app/oombly-arcade/id1626142692"
                  aria-label="Apple app store icon"
                >
                  <AppStoreBadge />
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OomblyInteractive;
