import React from "react";
import { useLocation } from "react-router-dom";

// Import all components that render within the Main component
import Header from "components/common/Header";
import Home from "components/common/Home";
import Footer from "components/common/Footer";

import Denali from "components/content/Denali";
import CodingDojo from "components/content/CodingDojo";
import Monscierge from "components/content/Monscierge";
import GPAGNW from "components/content/GPAGNW";
import Duolingo from "components/content/Duolingo";
import G33ks from "components/content/G33ks";
import Spectralux from "components/content/Spectralux";
import TinyEnt from "components/content/TinyEnt";
import Spartan from "components/content/Spartan";
import Chucktester from "components/content/Chucktester";
import Creamed from "components/content/Creamed";
import OomblyInteractive from "components/content/OomblyInteractive";
import EasyGames from "components/content/EasyGames";
import ThrusterBuster from "components/content/ThrusterBuster";
import NotFound from "components/common/NotFound";

// Any component rendered by Main should be added here
const mainComponents = {
  Home: Home,
  EasyGames: EasyGames,
  OomblyInteractive: OomblyInteractive,
  Denali: Denali,
  Monscierge: Monscierge,
  Spectralux: Spectralux,
  TinyEnt: TinyEnt,
  Creamed: Creamed,
  ThrusterBuster: ThrusterBuster,
  G33ks: G33ks,
  CodingDojo: CodingDojo,
  Duolingo: Duolingo,
  GPAGNW: GPAGNW,
  Spartan: Spartan,
  Chucktester: Chucktester,
  NotFound: NotFound,
};

const Main = ({ componentToRender }) => {
  const componentLoading = componentToRender;
  const CurrentComponent = mainComponents[componentLoading];
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div className="main">
      <Header currentPath={currentPath} />
      <div className="content">
        <CurrentComponent />
      </div>
      <Footer />
    </div>
  );
};

export default Main;
