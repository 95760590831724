import React from "react";
import { Row, Col, Card, Image, Accordion } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import easyGhost from "images/cards/easy_ghost_narrow.webp";
import umbra from "images/easy/umbra.webp";
import ignis from "images/easy/ignis.webp";
import zeno from "images/easy/zeno.webp";
import nyoka from "images/easy/nyoka.webp";
import comet_gif from "images/easy/comet_looped.gif";

import balloon from "images/easy/hot_air_balloon_small.webp";
import ufo from "images/easy/ufo.webp";


import Emoji from "components/common/Emoji";

const badges = [cardImages.typescriptIcon, cardImages.robloxStudioIcon, cardImages.githubIcon];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const EasyGames = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2022 - 2024</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <Row style={{ textAlign: "center" }}>
              <Col xs={4}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                xxl={2} className="mx-auto mb-4">
                <Image
                  src={easyGhost}
                  alt="The Easy Games logo"
                  fluid
                />
              </Col>
            </Row>

            <h1>Easy Games</h1>
            <p>I worked at Easy Games for nearly two years supporting weekly content updates for <a href="https://www.bedwars.com/">
              <b>Roblox Bedwars</b>
            </a>.</p>
            <p>
              My role as a Gameplay Programmer involved working closely with artists, animators, sound engineers and writers to design and develop content for the game. I was lucky enough to be involved from start to finish creating unique items and characters, limited time game modes and several flying vehicles.
            </p>
            <p>Bedwars is a team-based multiplayer game where players compete in various PVP and PVE modes. Gameplay includes 1st and 3rd person combat with a broad range of Kits and items available as well as building mechanics. The game has reached 8.9 billion visits and won the People's Choice at the <a href="https://blog.roblox.com/2022/09/roblox-innovation-awards-2022-complete-winners-list/">
              <b>2022 Roblox Innovation Awards</b>.
            </a>
            </p>
            <h2>Kits</h2>
            <p>
              Kits are similar to Champions, Heroes or Classes in similar PVP games. They include unique abilities and items and fall into common archetypes like: Support, Tank and Economy.
            </p>
            <p>Working on Kits was easily one of my favorite projects. This would include everything from brainstorming entirely new Kits to reworking existing ones. Brainstorming and iterating on designs with a creative and talented group of people was an absolute blast and seeing the community responses to our work on a regular basis was an incredible experience. </p>
            <Row style={{ textAlign: "center" }}>
              <Col xs={10} sm={10} md={4} lg={4} className="mx-auto">
                <Image
                  src={umbra}
                  alt="Render of Umbra"
                  fluid
                />
                <p>Umbra</p>
              </Col>
              <Col xs={10} sm={10} md={4} lg={4} className="mx-auto">
                <Image
                  src={ignis}
                  alt="Render of Ignis"
                  fluid
                />
                <p>Ignis</p>
              </Col>
              <Col xs={10} sm={10} md={4} lg={4} className="mx-auto">
                <Image
                  src={zeno}
                  alt="Render of Zeno"
                  fluid
                />
                <p>Zeno</p>
              </Col>
            </Row>
            <h3>Nyoka</h3>
            <p>Nyoka is a support Kit released during the Season X launch. They are a healing Kit who uses a staff to heal nearby allies while also gaining access to a Glide ability. I tend to enjoy playing support classes in many games and really wanted to challenge myself to create a unique healer that people would enjoy playing.</p>
            <p>I'm incredibly proud of how this character turned out.</p>
            <p>You can read all about Nyoka on
              <a href="https://robloxbedwars.fandom.com/wiki/Nyoka">
                <b> Bedwars Wiki</b>.
              </a></p>
            <Row style={{ textAlign: "center" }}>
              <Col xs={8} sm={8} md={6} lg={6} className="mx-auto">
                <Image
                  src={nyoka}
                  alt="Render of Nyoka"
                  fluid
                />
                <p>Nyoka</p>
              </Col>
            </Row>
            <h2>Vehicles</h2>
            <p>I had fun adding multiple flying vehicles to the game. Vehicles tended to be limited-time items and that allowed them to be pretty unconstrained in the mechanics they'd provide. I worked on everything from Hot Air Balloons that drop TNT on enemies below, to a UFO that allowed players to abduct allies and enemies.</p>
            <Row style={{ textAlign: "center" }}>
              <Col xs={10} sm={10} md={6} lg={6} className="mx-auto my-auto">
                <Image
                  src={balloon}
                  alt="Render of the Hot Air Balloon"
                  fluid
                />
                <p>Hot Air Balloon</p>
              </Col>
              <Col xs={10} sm={10} md={6} lg={6} className="mx-auto my-auto">
                <Image
                  src={ufo}
                  alt="Render of the UFO"
                  fluid
                />
                <p>UFO</p>
              </Col>
            </Row>

            <h2>Tech Stack</h2>
            <p>Our tech stack on Bedwars was really unique for the Roblox platform and it made smooth collaboration on a large scale project possible. Typically a game built in Roblox Studio would use Luau for the scripting, but in our case we used a Typescript to Luau compiler called <a href="https://roblox-ts.com/">
              <b> roblox-ts</b>
            </a> which provided access to all of the benefits Typescript provides. Working in this environment was great and reinforced my appreciation for solid tooling.</p>

            <Row style={{ textAlign: "center" }}>
              <Col xs={10} sm={10} md={10} lg={10} className="mx-auto my-auto">
                <Image src={comet_gif} fluid alt="A short gif demostrating a comet barrage on an enemy player." />
                <p>Comet Volley Enemy POV</p>
              </Col>

            </Row>

          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EasyGames;
