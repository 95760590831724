import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import BadgeRow from "components/cards/BadgeRow";
import SkillsList from "components/cards/SkillsList";
import Ribbon from "components/common/Ribbon";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TradingCard = ({ tradingCard }) => {
  const myTradingCard = tradingCard.cardData;

  const mySmallBadgeStyle = {
    myClassName: "float-right mr-2 smallBadges",
    myStyles: { width: 30, height: "auto" },
  };

  const title = myTradingCard.tradingCardTitle;
  const skillsList = myTradingCard.tradingCardSkills;
  const subtitle = myTradingCard.tradingCardSubtitle;
  const images = myTradingCard.images;
  const badges = myTradingCard.badges;
  // TODO: This is trimmed twice
  const trimmedTitle = "/" + title.toLowerCase().replace(/\s/g, "").replace("!","");
  const ariaLabel =
    "Learn more about " + title.replace(/\s/g, "");
  return (
    <Col xs={12} sm={12} md={6} lg={4} className="my-4">
      {/* Use title from card selected to append URL */}
      <Link to={trimmedTitle} className="card-link" aria-label={ariaLabel}>
        <Card
          className="shadow mx-auto card-main"
          // Pokemon cards are 5.3cm / 8.8cm - I multiplied each by x55
          style={{ width: 292, height: 484 }}
        >
          <Row className="my-2 px-2">
            <Col xs={10} sm={10} md={10} lg={10} className="text-left pl-4">
              <Card.Title className="">
                <small>
                  <strong>{title}</strong>
                </small>
              </Card.Title>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <BadgeRow
                tradingCardBadges={badges}
                badgeStyle={mySmallBadgeStyle}
              ></BadgeRow>
            </Col>
          </Row>
          <Card.Img
            variant="top"
            src={images}
            className="px-4 card-hero shadow-lg mx-auto"
          ></Card.Img>
          <Card.Body className="mt-1">
            <Row>
              <Col className="mx-2">
                <Card.Subtitle className="mb-4">
                  <Ribbon ribbonText={subtitle} ribbonLength="block"></Ribbon>
                </Card.Subtitle>
              </Col>
            </Row>

            <SkillsList skills={skillsList} />
          </Card.Body>
        </Card>{" "}
      </Link>
    </Col>
  );
};

TradingCard.propTypes = {
  tradingCardTitle: PropTypes.string,
  tradingCardSubtitle: PropTypes.string,
  tradingCardSkills: PropTypes.array,
  badges: PropTypes.array,
  tradingCardImage: PropTypes.any,
};

TradingCard.defaultProps = {
  tradingCard: {
    tradingCardTitle: "404",
    tradingCardSubtitle: "Add a subtitle!",
    tradingCardSkills: "DESCRIBE IT!",
    badges: ["ADD BADGES"],
    tradingCardImage: "Default Image Here",
  },
};

export default TradingCard;
