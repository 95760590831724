import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import BadgeRow from "../cards/BadgeRow";
import cardImages from "data/imageAssets";
import iphoneRepair from "images/iphone-repair.JPG";
import g33ksLogo from "images/g33ks-logo.png";

const badges = [cardImages.repairImage];
const myLargeBadgeStyle = {
  myClassName: "bigBadges mx-2 my-2",
  myStyles: { width: 90, height: "auto" },
};

const G33ks = () => {
  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12} className="mx-auto mb-2">
          <BadgeRow tradingCardBadges={badges} badgeStyle={myLargeBadgeStyle} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h2>2012 - 2013</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ textAlign: "left" }} className="p-4">
            <p>
              While working for a local AT&T store, I noticed a market for small
              electronics repairs in my small hometown. In 2012, I opened G33ks
              with a storefront in Jena Louisiana. I serviced broken Android and
              iOS devices as well as PC hardware. Additionally, I carried phone
              accessories.
            </p>
            <p>
              I loved helping people avoid costly hardware replacements while
              also getting the opportunity to learn more about electronic
              repairs. Being responsible for each aspect of running a business
              was a great learning experience and I'm grateful for the insight
              gained during this venture.{" "}
            </p>
            <p>
              After deciding to move to the Seattle area, I sold the business.
              It was around this time that I began considering learning more
              about the software that operated on the devices I had been
              repairing.
            </p>
            <p>G33ks is still operational today!</p>
            <h2>Ew...</h2>
            <p>
              My most memorable repair involved restoring a damaged iPhone that
              had been submerged in dip spit (Louisiana, remember?). The
              successful repair haunts me to this day.
            </p>
            <Row style={{ textAlign: "center" }}>
              <Col xs={12} sm={12} md={12} lg={12} className="mb-4 mx-auto">
                <Image
                  src={iphoneRepair}
                  alt="iPhone parts displayed outside of the phone housing during a repair"
                  fluid
                />
                <p>A mostly disassembled iPhone</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="mx-auto">
                <Image src={g33ksLogo} alt="The original G33ks logo" fluid />
                <p>Original G33ks Logo</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default G33ks;
